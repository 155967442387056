import { Breadcrumbs, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import MainCard from "components/MainCard";

type Props = {};

const BreadCrumb = (props: Props) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  if (
    pathnames.length < 2 ||
    (pathnames[0] == "inspections-management" && pathnames.length > 2)
  )
    return null;
  return (
    <MainCard className="mb-5">
      <Breadcrumbs
        separator="/"
        aria-label="breadcrumb"
        sx={{
          color: "#8c8c8c",
          "& a": {
            color: "#8c8c8c",
          },
        }}
      >
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          const showName = name.charAt(0).toUpperCase() + name.slice(1);
          return isLast ? (
            <Typography key={name} color="textPrimary" className=" capitalize">
              {showName.replace(/-/g, " ")}
            </Typography>
          ) : (
            <Link key={name} to={routeTo} className=" capitalize">
              {showName.replace(/-/g, " ")}
            </Link>
          );
        })}
      </Breadcrumbs>
    </MainCard>
  );
};

export default BreadCrumb;
