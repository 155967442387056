import { isExpiredToken } from "utils/helpers/main";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "config";
import { AccessTokenName, RefreshTokenName } from "utils/helpers/constant";

const refreshAccessToken = async () => {
  const response = await axios.post(
    BASE_URL + "/admin/v1/auth/refresh-token",
    {},
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem(RefreshTokenName)}`,
      },
    }
  );
  localStorage.setItem(AccessTokenName, response.data.data.accessToken);
  localStorage.setItem(RefreshTokenName, response.data.data.refreshToken);
  return response.data.data.accessToken;
};

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  validateStatus: function (status: any) {
    return status >= 200 && status < 300;
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;

    if (error.response && error.response.status === 401) {
      if (isExpiredToken(localStorage.getItem(RefreshTokenName))) {
        localStorage.removeItem(AccessTokenName);
        localStorage.removeItem(RefreshTokenName);
        localStorage.removeItem("isLoggedIn");
        window.location.replace("/login");
      } else {
        try {
          // Refresh the access token
          const newAccessToken = await refreshAccessToken();

          // Update the request headers with the new access token
          error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;

          // Retry all requests in the queue with the new token
          new Promise<void>((resolve, reject) => {
            return axiosInstance
              .request(error.config)
              .then((response: any) => resolve(response))
              .catch((err) => reject(err));
          });

          // Retry the original request
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          return null;
        }
      }
    }
    // Return a Promise rejection if the status code is not 401
    return Promise.reject(error);
  }
);
