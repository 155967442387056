export const PROPERTY_STATUS: Record<string, string> = {
  draft: "Draft",
  pending: "Pending",
  pending_payment: "Pending Payment",
  listed: "Listed",
  rented: "Rented",
  canceled: "Canceled",
};

export const CONTRACT_PAYMENT_TYPE: Record<string, string> = {
  rent: "Rent",
  property_commission: "property commission",
};

export const AccessTokenName = "access-token-admin-apollon";
export const RefreshTokenName = "refresh-token-admin-apollon";
